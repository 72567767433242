/**
 * Cipher Key Image Component
 */

import React from 'react'
import Image from '../../components/image'

class CipherKey extends React.Component {
  render() {
    return (
      <div
        style={
          {
            //height: '150px',
          }
        }>
        <Image alt="Cipher Key" filename="cipher-key.png" />
      </div>
    )
  }
}
export default CipherKey

import API from './api'

const isBrowser = typeof window !== `undefined`

const getMysteryMessageData = () => (window.localStorage.mmData ? JSON.parse(window.localStorage.mmData) : {})

export const setMysteryMessageData = data => (window.localStorage.mmData = JSON.stringify(data))

export const getCurrentMysteryMessageData = () => isBrowser && getMysteryMessageData()

export const setCurrentMysteryMessageData = data => isBrowser && setMysteryMessageData(data)

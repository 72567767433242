/**
 * Gold Code Input Component
 * 
 * Wrapper around the ReactCodeInput function, but styled to be gold
 */

import React from 'react'
import PropTypes from 'prop-types'
import ReactCodeInput from 'react-code-input'

export default class GoldInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numFields: 0,
    }
    this.inputRef = React.createRef()
    this.setNumFields = this.setNumFields.bind(this)
    this.checkCorrect = this.checkCorrect.bind(this)
  }

  componentDidMount() {
    this.setNumFields()
  }

  setNumFields() {
    const { answer } = this.props
    this.setState({
      numFields: answer.length,
    })
  }

  checkCorrect(value) {
    //console.log(value)
    this.props.onUpdate(value)
    if (value.replace(` `, ``).length == this.state.numFields) {
      // actually check if it's correct
      if (this.props.answer.toLowerCase() == value.toLowerCase()) {
        this.props.onCorrect()
      } else {
        this.props.onIncorrect()
      }
    }
  }

  render() {
    const { numFields } = this.state

    return (
      <div className="gold-input-container py-3 px-3">
        <ReactCodeInput
          ref={this.inputRef}
          autoFocus={false}
          type="text"
          fields={numFields}
          onChange={this.checkCorrect}
          forceUppercase={true}
          value={this.props.value}
          initialValue={this.props.initialValue}
          novalidate
        />
      </div>
    )
  }
}

GoldInput.defaultProps = {
  answer: ``,
  value: ``,
  onCorrect: () => {},
  onIncorrect: () => {},
  onUpdate: () => {},
}

GoldInput.propTypes = {
  answer: PropTypes.string,
  value: PropTypes.string,
  onCorrect: PropTypes.func,
  onIncorrect: PropTypes.func,
  onUpdate: PropTypes.func,
  initialValue: PropTypes.string,
}

/**
 * Mystery Message Input Component
 * 
 * Display the mystery message glyphs, with blank inputs underneath each letter,
 * for the user to decode.
 */

import React from 'react'
import ReactCodeInput from 'react-code-input'
import { isTeamCaptain } from '../../utils/team-data'
import { b64DecodeUnicode } from '../../utils/helpers'

export default class MysteryMessageInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.checkAnswer = this.checkAnswer.bind(this)
  }

  componentDidMount() {
    this.setState({ values: this.props.initialValues })
  }

  printWord(letters) {
    return (
      <span className="word">
        {letters.map((letter, index) => (
          <span key={`letter-${letter}-${index}`} className={`letter letter-${letter}`} />
        ))}
      </span>
    )
  }

  handleChange(index, value) {
    let newValues = [...this.state.values]
    newValues[index] = value
    this.setState({ values: newValues })
    //console.log(newValues)
    this.props.onUpdate(newValues)
  }

  checkAnswer() {
    const { answer, onCorrect, onIncorrect } = this.props
    const guess = this.state.values.join(` `)
    let l_answer = b64DecodeUnicode(answer).toLowerCase()
    if (l_answer == guess.toLowerCase()) {
      //console.log('Correct guess')
      onCorrect()
    } else {
      //console.log('Incorrect Guess')
      onIncorrect()
    }
  }

  render() {
    const { message, initialValues, disabled } = this.props
    const isCaptain = isTeamCaptain()
    return (
      <div className="mystery-message mystery-message-input">
        {message.map((part, index) => (
          //console.log(part)
          <div className="part mb-3" key={`part-${index}`}>
            {this.printWord(part)}
            <ReactCodeInput
              autoFocus={false}
              type="text"
              fields={part.length}
              onChange={value => {
                this.handleChange(index, value)
              }}
              initialValue={
                initialValues[index] !== undefined && initialValues[index] !== null ? initialValues[index] : ``
              }
              forceUppercase={true}
              novalidate
            />
          </div>
        ))}
        {!disabled && isCaptain && (
          <button className="btn btn-secondary btn-block btn-arrow" onClick={this.checkAnswer}>
            Submit Answer!
          </button>
        )}
      </div>
    )
  }
}

MysteryMessageInput.defaultProps = {
  message: [],
  answer: ``,
  initialValues: [],
  onUpdate: () => {},
  onCorrect: () => {},
  onIncorrect: () => {},
  disabled: false,
}

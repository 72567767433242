/**
 * Multimedia Question Component
 * 
 * Used in the Mystery Message. Shows an individual multimedia question,
 * with the media, plus the answer spaces.
 */

import React, {useState, useEffect} from 'react'
import ReactCodeInput from 'react-code-input'
import AudioPlayer from './audio-player'
import { b64DecodeUnicode, getHighlightedIndexes } from '../utils/helpers'

const MultimediaQuestion = ({index=0, question={}, onUpdate=() => {}, initialValue=``}) => {
  const [answer, setAnswer] = useState(``)
  const [spacesAnswers, setSpacesAnswers] = useState([])
  const [highlightIndex, setHighlightIndex] = useState()
  const [initialWords, setInitialWords] = useState([])


  useEffect(() => {
    const spaces = getSpaces()
    let index = spaces.indexOf(`[`)
    setHighlightIndex(index)
  }, [question])

  useEffect(() => {
    if(answer !== `` && answer[highlightIndex] !== undefined && answer[highlightIndex] !== ` `){
      onUpdate(question.id, answer, answer[highlightIndex], index)
    }
  }, [answer])

  useEffect(() => {
    setInitialWords(initialValue.split(` `))
  }, [initialValue])

  const getSpaces = () => {
    const answer = b64DecodeUnicode(question.answer_aes_crypt).trim()
    var spaces = answer.replace(/[A-Za-z0-9]/g, `_`)
    spaces = spaces.replace(/\s+/g, `+`)
    return spaces
  }

  const handleSpacesUpdate = (value, index) => {
    spacesAnswers[index] = value
    setSpacesAnswers(spacesAnswers)
    setAnswer(spacesAnswers.join(` `))
  }

  const getMediaElement = (type, url) => {
    if (type == 0) {
      return <AudioPlayer source={url} />
    }
  }

  const answer_spaces = getSpaces()
  const words = answer_spaces.split(`+`)

  return (
    <div className="multimedia-question mb-3">
      {getMediaElement(question.media_type, question.media)}
      {words.map((word, idx) => {
        const num_spaces = word.match(/_/g).length
        const highlighted_indexes = getHighlightedIndexes(word)
        return(
          <span className="answer-input d-inline-block mr-3 mb-3" key={question.id+`-`+idx}>
            <ReactCodeInput 
              label={`character-${idx}`}
              type="text"
              fields={num_spaces}
              autoFocus={false}
              forceUppercase={true}
              initialValue={initialWords[idx]}
              highlightCharIndex={highlighted_indexes}
              onChange={(value) => handleSpacesUpdate(value, idx)}
            />
          </span>
        )
      }
      )}
      <p className="hint">{question.hint}</p>
    </div>
  )
}

export default MultimediaQuestion
/**
 * Mystery Message Page
 * 
 * Handles all of the logic for the mystery message game section.
 * 
 * There are 3 main steps in the mystery message. First step is a riddle that uses the team answers,
 * second step is a fill in the blanks section based on audio clips, 3rd step is actually completing
 * what the mystery message is, based on the pig pen cipher that is provided.
 */

import React from 'react'
import { getHuntStatus, isTeamCaptain, teamAnswerMysteryMessage, getCurrentTeamData } from '../utils/team-data'
import LoggedInWrapper from '../components/loggedinWrapper'
import Slider from 'react-slick'
import Modal from 'react-bootstrap/Modal'
import CountUp from 'react-countup'
import striptags from 'striptags'

import { getCurrentMysteryMessageData, setCurrentMysteryMessageData } from '../utils/mystery-message-data'

import SvgArrow from '../components/svg/arrow'
import Link from '../components/link'
import MysteryMessage from '../components/mystery-message/message'
import MultimediaQuestion from '../components/multimedia-question'
import CipherKey from '../components/images/cipher-key'
import GoldInput from '../components/gold-input'
import MysteryMessageInput from '../components/mystery-message/message-input'
import { DirigibleIllustration, CrownIllustration, KeysIllustration } from '../components/images/illustrations'
import SvgCheck from '../components/svg/check'
import { navigate } from 'gatsby'
import FloatingInput from '../components/floating-input'
import { getSiteData } from '../utils/site'
import { b64DecodeUnicode, getStaticUrl } from '../utils/helpers'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'

export default class MysteryMessagePage extends React.Component {
  constructor(props) {
    super(props)
    const mmData = getCurrentMysteryMessageData()
    this.state = {
      data: getCurrentHuntData(),
      initialData: mmData,
      globalSettings: getSiteData(),
      hunt_status: `pending`,
      play_sounds: true,
      success_sound: getStaticUrl(`/sounds/ding.mp3`),
      soft_fail_sound: getStaticUrl(`/sounds/duck.mp3`),
      points: mmData.points ? mmData.points : 0,
      possible_points: 15,
      current_step: mmData.current_step ? mmData.current_step : 0,
      step1answer: mmData.step1answer ? mmData.step1answer : ``,
      showStep1Hint: mmData.showStep1Hint ? mmData.showStep1Hint : false,
      step2answer: mmData.step2answer ? mmData.step2answer : ``,
      showStep2Hint: mmData.showStep2Hint ? mmData.showStep2Hint : false,
      step3answer: mmData.step3answer ? mmData.step3answer : ``,
      mm_question_answers: mmData.mm_question_answers ? mmData.mm_question_answers : {},
      modalShow: false,
      modalCompleteShow: false,
      is_complete: false,
      incorrectModalShow: false,
      incorrectModalTitle: `Step 1`,
      successModalTitle: `Congratulations`,
      successModalText: ``,
      successModalAnswer: ``,
      successModalCyper: false,
    }
    this.firstClientX = 0
    this.sliderContainer = React.createRef()
    this.preventTouch = this.preventTouch.bind(this)
    this.touchStart = this.touchStart.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
    this.codeRef = React.createRef()
    this.handleChange = this.handleChange.bind(this)
    this.goStep1 = this.goStep1.bind(this)
    this.step1AnswerUpdate = this.step1AnswerUpdate.bind(this)
    this.checkStep1Answer = this.checkStep1Answer.bind(this)
    this.step1Incorrect = this.step1Incorrect.bind(this)
    this.step1Correct = this.step1Correct.bind(this)
    this.step2Incorrect = this.step2Incorrect.bind(this)
    this.step2Correct = this.step2Correct.bind(this)
    this.step2AnswerUpdate = this.step2AnswerUpdate.bind(this)
    this.step3Correct = this.step3Correct.bind(this)
    this.step3Incorrect = this.step3Incorrect.bind(this)
    this.step3AnswerUpdate = this.step3AnswerUpdate.bind(this)
    this.checkStep2 = this.checkStep2.bind(this)
    this.update = this.update.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.modalCompleteClose = this.modalCompleteClose.bind(this)
    this.incorrectModalClose = this.incorrectModalClose.bind(this)
    this.playSuccess = this.playSuccess.bind(this)
    this.playSoftFail = this.playSoftFail.bind(this)
  }

  componentDidMount() {
    this.sliderContainer.current.addEventListener(`touchstart`, this.touchStart)
    this.sliderContainer.current.addEventListener(`touchmove`, this.preventTouch, { passive: false })

    let hunt_status = getHuntStatus()
    const huntData = getCurrentHuntData()
    const { globalSettings } = this.state
    let success_text = `Good job smarty pants!`
    if (
      huntData.hunt !== undefined &&
      huntData.hunt.settings !== null &&
      huntData.hunt.settings.success_text !== null
    ) {
      success_text = huntData.hunt.settings.success_text
    } else if (globalSettings !== undefined && globalSettings.success_text !== undefined) {
      success_text = globalSettings.success_text
    }

    let success_sound = huntData.hunt.sound_success ? huntData.hunt.sound_success.file : getStaticUrl(`/sounds/ding.mp3`)
    let soft_fail_sound = huntData.hunt.sound_soft_fail ? huntData.hunt.sound_soft_fail.file : getStaticUrl(`/sounds/duck.mp3`)
    let play_sounds = huntData.hunt.play_sounds == 1

    let possible_points = huntData.mystery_message !== undefined ? huntData.mystery_message.point_value : 15
    this.setState(
      {
        hunt_status: hunt_status,
        data: huntData,
        possible_points: possible_points,
        successModalText: success_text,
        play_sounds: play_sounds,
        success_sound: success_sound,
        soft_fail_sound: soft_fail_sound,
      },
      () => {
        var step2_ans_len = b64DecodeUnicode(huntData.mystery_message.step_2_answer_aes_crypt).length
        if (this.state.step2answer == undefined || this.state.step2answer.length !== step2_ans_len) {
          this.setState({
            step2answer: ` `.repeat(step2_ans_len),
          })
        }
        this.setLoading(false)
      }
    )
    this.success_audio = new Audio(success_sound)
    this.soft_fail_audio = new Audio(soft_fail_sound)
    
    if (this.state.current_step > 0) {
      this.slider.slickGoTo(this.state.current_step, true)
    }

    this.interval = setInterval(() => this.update(), 5 * 1000)
  }

  componentWillUnmount() {
    this.sliderContainer.current.removeEventListener(`touchstart`, this.touchStart)
    this.sliderContainer.current.removeEventListener(`touchmove`, this.preventTouch, {
      passive: false,
    })
    clearInterval(this.interval)
  }

  playSuccess() {
    if (this.state.play_sounds) {
      this.success_audio.play()
    }
  }

  playSoftFail() {
    if (this.state.play_sounds) {
      this.soft_fail_audio.play()
    }
  }

  update() {
    // console.log(`updating local Mystery Message...`)
    // console.log(this.state.mm_question_answers)
    setCurrentMysteryMessageData({
      points: this.state.points,
      current_step: this.state.current_step,
      step1answer: this.state.step1answer,
      step2answer: this.state.step2answer,
      step3answer: this.state.step3answer,
      mm_question_answers: this.state.mm_question_answers,
      showStep1Hint: this.state.showStep1Hint,
      showStep2Hint: this.state.showStep2Hint,
    })

    const teamData = getCurrentTeamData()
    if (teamData.mystery_message_answers && teamData.mystery_message_answers.points !== undefined) {
      let points = teamData.mystery_message_answers.points
      this.setState({
        points: points,
        is_complete: points == this.state.possible_points,
      })
    }
  }

  modalClose() {
    this.setState({ modalShow: false }, () => {
      setTimeout(this.nextSlide, 500)
    })
  }

  modalCompleteClose() {
    this.setState({ modalCompleteShow: false })
    navigate(`/`) // go back to the overview page
  }

  incorrectModalClose() {
    this.setState({ incorrectModalShow: false })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleUpdate(word) {
    console.log(word)
  }

  nextSlide() {
    window.scrollTo(0, 0) // scroll back to the top when going to the next slide programmatically
    this.slider.slickNext()
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
  }

  preventTouch(e) {
    const minValue = 5 // threshold

    let diff = e.touches[0].clientX - this.firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(diff) > minValue) {
      // e.preventDefault()
      e.returnValue = false
      return false
    }
  }

  goStep1() {
    if (this.state.current_step < 1) {
      // console.log(`going to step 1`)
      this.setState(
        {
          current_step: 1,
        },
        () => {
          this.nextSlide()
        }
      )
    } else {
      // console.log(`going to next slide`)
      this.nextSlide()
    }
  }

  step1AnswerUpdate(event) {
    this.setState({
      step1answer: event.target.value,
    })
  }

  checkStep1Answer(event) {
    event.preventDefault()
    const { step1answer, data } = this.state
    if (step1answer.toLowerCase() == b64DecodeUnicode(data.mystery_message.step_1_answer_aes_crypt).toLowerCase()) {
      this.step1Correct()
    } else {
      this.step1Incorrect()
    }
  }

  step1Correct() {
    const { data } = this.state
    // console.log(`step 1 correct!`)
    if (this.state.current_step < 2) {
      this.setState({
        current_step: 2,
        modalShow: true,
        successModalAnswer: b64DecodeUnicode(data.mystery_message.step_1_answer_aes_crypt),
        successModalTitle: data.mystery_message.step_1_title,
      })
      this.playSuccess()
    }
  }

  step1Incorrect() {
    // console.log(`step 1 incorrect!`)
    this.setState({
      incorrectModalShow: true,
      incorrectModalTitle: this.state.data.mystery_message.step_1_title,
      showStep1Hint: true,
    })
    this.playSoftFail()
  }

  step2AnswerUpdate(mm_q_id, value, letter, index) {
    // console.log(`MM Question: ${mm_q_id} Value: ${value}`)
    // console.log(`index: ${index} letter: ${letter}`)
    this.setState(prevState => {
      return {
        mm_question_answers: {
          ...prevState.mm_question_answers,
          [mm_q_id]: value,
        },
      }
    })
    var curGuess = this.state.step2answer
    if (letter) {
      let newGuess = curGuess.substring(0, index) + letter + curGuess.substring(index + 1)
      this.setState(
        {
          step2answer: newGuess,
        },
        () => {
          this.checkStep2()
        }
      )
    }
  }

  checkStep2() {
    // console.log('checking step 2 answer...')
    const { data, step2answer } = this.state
    // console.log(step2answer)
    if (b64DecodeUnicode(data.mystery_message.step_2_answer_aes_crypt).toLowerCase() == step2answer.toLowerCase()) {
      this.step2Correct()
    }
  }

  step2Correct() {
    console.log(`step 2 correct!`)
    if (this.state.current_step < 3) {
      this.setState({
        current_step: 3,
        modalShow: true,
        successModalCyper: true,
        successModalTitle: `Congratulations!`,
      })
      this.playSuccess()
    }
  }

  step2Incorrect() {
    console.log(`step 2 incorrect!`)
    this.setState({
      incorrectModalShow: true,
      incorrectModalTitle: this.state.data.mystery_message.step_2_title,
      showStep2Hint: true,
    })
    this.playSoftFail()
  }

  step3Correct() {
    console.log(`step 3 correct!`)
    const { possible_points } = this.state

    // add the points
    this.setState({
      points: possible_points,
      modalCompleteShow: true,
      is_complete: true,
    })
    this.playSuccess()

    if (isTeamCaptain()) {
      teamAnswerMysteryMessage({ points: possible_points })
    }
  }

  step3Incorrect() {
    console.log(`step 3 incorrect!`)
    this.setState({
      incorrectModalShow: true,
      incorrectModalTitle: `Mystery Message`,
    })
    this.playSoftFail()
  }

  step3AnswerUpdate(values) {
    //console.log('step 3 answer updated')
    this.setState({ step3answer: values })
  }

  render() {
    const { data, current_step, points, is_complete, possible_points, showStep1Hint, showStep2Hint } = this.state

    var sliderSettings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    }

    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return (
            <LoggedInWrapper>
              <div className="site-sub-header px-4 py-2">
                <div className="row">
                  <div className="col-2 d-flex align-items-center">
                    <Link to={`/`} className={`back-arrow`}>
                      <SvgArrow />
                    </Link>
                  </div>
                  <div className="col-10 text-right">
                    <h1 className={`title h5 smaller text-uppercase mb-0 d-inline`}>Mystery Message</h1>
                    <span className="points title h5 smaller ml-2">
                      ({points}/{possible_points})
                    </span>
                    {is_complete && (
                      <span
                        className={`check-circle smaller correct ml-2`}
                        style={{
                          position: `relative`,
                          top: `-3px`,
                        }}>
                        <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                          <SvgCheck />
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={`mt-3 mb-3`}>
                <div ref={this.sliderContainer}>
                  <Slider
                    className={`mystery-message-slider`}
                    ref={slider => (this.slider = slider)}
                    {...sliderSettings}>
                    <div>
                      <div className="card mx-4">
                        <div className="card-header text-center">
                          <h3>Decode This</h3>
                        </div>
                        <div className="card-body pb-3">
                          {data.mystery_message !== undefined && (
                            <>
                              <MysteryMessage message={data.mystery_message.mystery_message_parts} />
                              <div
                                className={`mt-3`}
                                dangerouslySetInnerHTML={{ __html: data.mystery_message.intro_text }}
                              />
                            </>
                          )}
                          <button className="btn btn-secondary btn-block" onClick={this.goStep1}>
                            Get Started!
                          </button>
                        </div>
                      </div>
                    </div>

                    {current_step >= 1 && (
                      <div className="step-1">
                        <div className="card question-card mx-4">
                          <div className="card-header text-center">
                            <h3>{data.mystery_message !== undefined ? data.mystery_message.step_1_title : `Step 1`}</h3>
                          </div>
                          <div className="card-body pb-3">
                            {data.mystery_message !== undefined && (
                              <>
                                <div dangerouslySetInnerHTML={{ __html: data.mystery_message.step_1_riddle }} />
                                <div dangerouslySetInnerHTML={{ __html: data.mystery_message.step_1_question }} />
                                {showStep1Hint && (
                                  <small className="hint mb-3">
                                    <strong className="hint-title subtitle h3 text-uppercase">Hint:</strong>
                                    {` `}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: striptags(data.mystery_message.step_1_hint, [`strong`, `i`]),
                                      }}
                                    />
                                  </small>
                                )}
                                {current_step <= 1 ? (
                                  <form
                                    className={`question-form mt-auto pb-3`}
                                    method="post"
                                    onSubmit={event => this.checkStep1Answer(event)}>
                                    <FloatingInput
                                      name={`step1answer`}
                                      placeholder={
                                        data.mystery_message.step_1_help_text
                                          ? data.mystery_message.step_1_help_text
                                          : ``
                                      }
                                      onChange={this.step1AnswerUpdate}
                                      value={this.state.step1answer}
                                    />
                                    <input
                                      className={`btn btn-block btn-danger`}
                                      type="submit"
                                      value="Submit Answer!"
                                    />
                                  </form>
                                ) : (
                                  <p className="pb-3 title h5 text-uppercase text-center mt-auto">
                                    <strong>Answer:</strong>
                                    {` `}
                                    {b64DecodeUnicode(data.mystery_message.step_1_answer_aes_crypt)}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                          {/* <div className="card-footer px-0 py-0">
                      <GoldInput
                        answer={b64DecodeUnicode(data.mystery_message.step_1_answer_aes_crypt)}
                        initialValue={
                          this.state.initialData.step1answer !== undefined ? this.state.initialData.step1answer : ''
                        }
                        value={this.state.step1answer}
                        onUpdate={value => {
                          this.setState({ step1answer: value })
                        }}
                        onCorrect={this.step1Correct}
                        onIncorrect={this.step1Incorrect}
                      />
                    </div> */}
                        </div>
                      </div>
                    )}

                    {current_step >= 2 && (
                      <div className="step-2">
                        <div className="card question-card mx-4">
                          <div className="card-header text-center">
                            <h3>{data.mystery_message !== undefined ? data.mystery_message.step_2_title : `Step 2`}</h3>
                          </div>
                          <div className="card-body pb-3">
                            {data.mystery_message !== undefined && (
                              <>
                                <div dangerouslySetInnerHTML={{ __html: data.mystery_message.step_2_text }} />
                                {data.mystery_message.multimedia_questions.map((question, index) => (
                                  <MultimediaQuestion
                                    key={`mm-question-${index}`}
                                    question={question}
                                    initialValue={
                                      this.state.initialData.mm_question_answers !== undefined &&
                                      this.state.initialData.mm_question_answers[question.id] !== undefined
                                        ? this.state.initialData.mm_question_answers[question.id]
                                        : ``
                                    }
                                    index={index}
                                    onUpdate={this.step2AnswerUpdate}
                                  />
                                ))}
                                {showStep2Hint && (
                                  <small className="hint mb-3">
                                    <strong className="hint-title subtitle h3 text-uppercase">Hint:</strong>
                                    {` `}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: striptags(data.mystery_message.step_2_hint, [`strong`, `i`]),
                                      }}
                                    />
                                  </small>
                                )}
                              </>
                            )}
                          </div>
                          <div className="card-footer px-0 py-0">
                            <GoldInput
                              answer={b64DecodeUnicode(data.mystery_message.step_2_answer_aes_crypt)}
                              initialValue={
                                this.state.initialData.step2answer !== undefined
                                  ? this.state.initialData.step2answer
                                  : ``
                              }
                              value={this.state.step2answer}
                              onCorrect={this.step2Correct}
                              onIncorrect={this.step2Incorrect}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {current_step >= 3 && (
                      <div className="step-3">
                        <div className="card question-card mx-4">
                          <div className="card-header text-center">
                            <h3>Use the Cypher</h3>
                          </div>
                          <div className="card-body pb-3">
                            {data.mystery_message !== undefined && (
                              <>
                                <div className="pb-4">
                                  <CipherKey />
                                  <div
                                    className={`pt-3`}
                                    dangerouslySetInnerHTML={{ __html: data.mystery_message.step_3_text }}
                                  />
                                </div>

                                <MysteryMessageInput
                                  message={data.mystery_message.mystery_message_parts}
                                  answer={data.mystery_message.step_3_answer_aes_crypt}
                                  initialValues={this.state.step3answer}
                                  onCorrect={this.step3Correct}
                                  onIncorrect={this.step3Incorrect}
                                  onUpdate={this.step3AnswerUpdate}
                                  disabled={is_complete}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Slider>
                </div>
              </div>
              <Modal
                show={this.state.incorrectModalShow}
                onHide={this.modalClose}
                centered
                dialogClassName={`defaultDialog`}
                backdropClassName={`app-overlay`}>
                <Modal.Body>
                  <div className={`card question-modal-card`}>
                    <div className={`card-header text-center`}>
                      <h3>{this.state.incorrectModalTitle}</h3>
                    </div>
                    <div className={`card-body px-3 py-4 text-center`}>
                      <div className="incorrect-answer py-5 px-5 text-center">
                        <KeysIllustration />
                        <p className="mt-3">Sorry, that's not the correct answer!</p>
                      </div>
                    </div>
                    <div className={`card-footer py-3 no-background`}>
                      <button className={`btn btn-success btn-block`} onClick={this.incorrectModalClose}>
                        Try Again
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.modalShow}
                onHide={this.modalClose}
                centered
                dialogClassName={`defaultDialog`}
                backdropClassName={`app-overlay`}>
                <Modal.Body>
                  <div className={`card question-modal-card success`}>
                    <div className={`card-header text-center`}>
                      <h3>{this.state.successModalTitle}</h3>
                    </div>
                    <div className={`card-body px-3 py-4 text-center`}>
                      {this.state.successModalCyper ? (
                        <>
                          <p>
                            <strong className={`text-uppercase`}>You Unlocked The Cypher!</strong>
                          </p>
                          <CipherKey />
                          <p className={`mt-3`}>Use it to decode the Mystery Message</p>
                          <DirigibleIllustration />
                        </>
                      ) : (
                        <>
                          <div className="question-end">
                            <p className={`answer`}>Answer: {this.state.successModalAnswer}</p>
                            <p className={`end-text`}>{this.state.successModalText}</p>
                            <div className="d-flex justify-content-center">
                              <div className={`icon-container`}>
                                <CrownIllustration />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={`card-footer py-3 `}>
                      <button className={`btn btn-danger btn-block btn-arrow`} onClick={this.modalClose}>
                        Continue
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.modalCompleteShow}
                onHide={this.modalCompleteClose}
                centered
                dialogClassName={`defaultDialog`}
                backdropClassName={`app-overlay`}>
                <Modal.Body>
                  <div className={`card question-modal-card success`}>
                    <div className={`card-header text-center`}>
                      <div className={`row align-items-center justify-content-between`}>
                        <div className={`col-8`}>
                          <h3 className={`mb-0`}>Mystery Message</h3>
                        </div>
                        <div className={`col-4 text-right`}>
                          <span className={`check-circle`}>
                            <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                              <SvgCheck />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`card-body`}>
                      <div className="question-end py-3">
                        <div className="text-center">
                          <p className={`answer`}>You Did It!</p>
                          <div className="row no-gutters align-items-center justify-content-center mb-3 points-container">
                            <div className="col text-right">
                              You
                              <br />
                              earned
                            </div>
                            <div className="col-6 points px-2">
                              <CountUp end={this.state.points} delay={1} /> / {possible_points}
                            </div>
                            <div className="col text-left">
                              possible
                              <br />
                              points!
                            </div>
                          </div>
                          <p className={`end-text`}>{this.state.successModalText}</p>
                          <div className="d-flex justify-content-center">
                            <div className={`icon-container`}>
                              <CrownIllustration />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`card-footer py-3 `}>
                      <button className={`btn btn-danger btn-block btn-arrow`} onClick={this.modalCompleteClose}>
                        Continue
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

/**
 * Audio Player Component
 * 
 * Used in the Mystery Message Multimedia Questions
 */

import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

export default class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      play_state: `stopped`,
      currentTime: 0,
      duration: 0,
    }

    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
  }

  componentDidMount() {
    this.player.addEventListener(`timeupdate`, e => {
      this.setState({
        currentTime: this.player.currentTime,
      })
    })
    this.player.addEventListener(`loadeddata`, () => {
      this.setState({
        duration: this.player.duration,
      })
    })

    this.player.addEventListener(`ended`, () => {
      this.setState({
        play_state: `stopped`,
      })
    })
  }

  componentWillUnmount() {
    this.player.removeEventListener(`timeupdate`, () => {})
    this.player.removeEventListener(`loadeddata`, () => {})
    this.player.removeEventListener(`ended`, () => {})
  }

  play() {
    //console.log('playing')
    this.player.play()
    this.setState({ play_state: `playing` })
  }

  pause() {
    //console.log('pausing')
    this.player.pause()
    this.setState({ play_state: `paused` })
  }

  getTime(time) {
    if (!isNaN(time)) {
      return Math.floor(time / 60) + `:` + (`0` + Math.round(time % 60)).slice(-2)
    }
  }

  render() {
    const { source } = this.props
    const { play_state, currentTime, duration } = this.state
    return (
      <div className="audio-player row mb-3">
        <div className="col-2">
          {(play_state === `paused` || play_state === `stopped`) && (
            <button className="btn btn-link play-button px-0 py-0" onClick={this.play} title="Play">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g fill="none" fillRule="evenodd">
                  <path fill="#273A41" d="M19.887 12.5L8.523 18.75V6.25z" />
                  <circle cx="12.5" cy="12.5" r="12" stroke="#273A41" />
                </g>
              </svg>
            </button>
          )}
          {play_state === `playing` && (
            <button className="pause-button btn btn-link px-0 py-0" onClick={this.pause} title="Pause">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g fill="none" fillRule="evenodd">
                  <circle cx="12.5" cy="12.5" r="12" stroke="#273A41" />
                  <path fill="#273A41" d="M7 6h4v13H7zM14 6h4v13h-4z" />
                </g>
              </svg>
            </button>
          )}
        </div>
        <div className="col-10">
          <div className="progress-container">
            <div className="row">
              <div className="col">{this.getTime(currentTime)}</div>
              <div className="col text-right">{this.getTime(duration)}</div>
            </div>
            <ProgressBar 
              className={`audio-progress`} 
              now={(currentTime / duration) * 100} 
              label={`${currentTime}/${duration}s`} 
              srOnly />
          </div>
        </div>
        <audio ref={ref => (this.player = ref)}>
          <source src={source} type="audio/mpeg"></source>
        </audio>
      </div>
    )
  }
}

/**
 * Mystery Message Component
 * 
 * Display the mystery message in glyphs only.
 */

import React from 'react'

export default class MysteryMessage extends React.Component {
  printWord(letters) {
    return (
      <span className="word">
        {letters.map((letter, index) => (
          <span key={`letter-${letter}-${index}`} className={`letter letter-${letter}`} />
        ))}
      </span>
    )
  }

  render() {
    const { message } = this.props
    return (
      <div className="mystery-message">
        {message.map((part, index) => (
          //console.log(part)
          <span className="part" key={`part-${index}`}>
            {this.printWord(part)}
          </span>
        ))}
      </div>
    )
  }
}
